import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPhoneNumber, loginHistoryItem} from "../../types/interfaces";

type UserState = {
    error: any,
    userToken: string,
    user: userType,
    registrationWasSuccess: boolean | null,
    accountsList: any,
    cardInfo: cardInfoType,
    isLoading: boolean,
    disabled: boolean
    currencyExchange: any
    currencyExchangeCheck: any
    currencyExchangeResult: any
    currencyExchangeResultCheck: any
    tokenIsValid:boolean
    accountTypesList: accountTypeItem[],
    chosenAccountTypeId: null | number,
    sessionId: number | null
    loginHistory: loginHistoryItem[]
    userLimits: userLimitsType
}

type kycRejectedReasonType = {
    statusReason: string,
    isShowReasonConfidentiality: boolean,
    isShowButton: boolean
}

type userType = {
    userData: userDataType,
    kycList: string[],
    phoneList: IPhoneNumber[],
    is2Fa: boolean,
    verificationsList: verificationListType
    flags: flagsType,
    kycRejectedReason: null | kycRejectedReasonType
}

export type userLimitsType = {
    dailyLimit: number | null,
    monthlyLimit: number | null,
    currentDailyAmount: number | null,
    currentMonthlyAmount: number | null
}

type verificationListType = {
    emailVerifyEnabled: boolean,
    twoFaVerifyEnabled: boolean,
    phoneVerifyEnabled: boolean,
}

type flagsType = {
    kycFormStatus: string | null,
    kycVerificationStatus: string | null,
    autoLogout: boolean | undefined,
    sendEmailOnLogin: boolean | undefined,
    logoutIfIpChanges: boolean | undefined,
    saveLoginHistory: boolean | undefined,
    verifyWithdrawal: boolean | undefined,
    blockWithdrawal: boolean | undefined,
}

type cardInfoType = {
    phone: string,
    email: string,
    cardHolder: string,
    cardNumber: string,
    san: string,
    cvv: string,
    expireDate: string,
    balance: number,
    type: string | number,
    typeText: string,
    status: string | number,
    statusText: string,
    shippingAddress: shippingAddressType,
    shippingTrackingNo: string,
    description: string
}

type shippingAddressType = {
    DelvAddress1: string,
    DelvCity: string,
    DelvCountyState: string | null,
    DelvCountryCode: string | null,
    DelvPostCode: string,
    Delivery: string,
}

export type userDataType = {
    address: string | null
    callPhone: string | null
    city: string | null
    clientId: string | null
    companyName: string | null
    country: string | null
    email: string | null
    fax: string | null
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    postCode: string | null
    salutation: string | null
    stateOrProvince: string | null
    accountType: string | null
}

export type accountTypeItem = {
    id: number,
    owner: string,
    accountType: string,
    activeStatus: boolean
}

const initialState: UserState = {
    error: null,
    userToken: "",
    accountTypesList: [],
    loginHistory: [],
    user: {
        verificationsList: {
            emailVerifyEnabled :true,
            twoFaVerifyEnabled :false,
            phoneVerifyEnabled :false,
        },
        flags: {
            kycFormStatus: null,
            kycVerificationStatus: null,
            autoLogout: undefined,
            sendEmailOnLogin: undefined,
            logoutIfIpChanges: undefined,
            saveLoginHistory: undefined,
            verifyWithdrawal: undefined ,
            blockWithdrawal: undefined,
        },
        userData: {
            address: "",
            callPhone: "",
            city: "",
            clientId: "",
            companyName: "",
            country: "",
            email: "",
            fax: "",
            firstName: "",
            lastName: "",
            postCode: "",
            phoneNumber: "",
            salutation: "",
            stateOrProvince: "",
            accountType: "business",
        },
        kycRejectedReason: null,
        kycList: [],
        phoneList: [],
        is2Fa: false,
    },
    registrationWasSuccess: null,
    accountsList: [],
    cardInfo: {
        phone: "",
        email: "",
        cardHolder: "",
        cardNumber: "",
        san: "",
        cvv: "",
        expireDate: "",
        balance: 0,
        shippingAddress: {
            DelvAddress1: "",
            DelvCity: "",
            DelvCountyState: "",
            DelvCountryCode: "",
            DelvPostCode: "",
            Delivery: "",
        },
        shippingTrackingNo: "",
        type: "",
        typeText: "",
        status: "",
        statusText: "",
        description: ""
    },
    currencyExchange: {
        amount: '',
        toAmount: '',
        to: '',
        from: ''
    },
    currencyExchangeCheck: {
        amount: '',
        toAmount: '',
        to: '',
        from: ''
    },
    currencyExchangeResult: null,
    currencyExchangeResultCheck: null,
    userLimits: {
        dailyLimit: null,
        monthlyLimit: null,
        currentDailyAmount: null,
        currentMonthlyAmount: null
    },
    isLoading: false,
    disabled: false,
    tokenIsValid: true,
    chosenAccountTypeId: null,
    sessionId: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData(state, action) {
            state.user = action.payload
            state.error = null
        },
        userAuthorizationError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        signOut(state) {
            state.userToken = ""
            state.user.userData = {
                address: null,
                callPhone: null,
                city: null,
                clientId: null,
                companyName: null,
                country: null,
                email: null,
                fax: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                postCode: null,
                salutation: null,
                stateOrProvince: null,
                accountType: 'personal'
            }
            state.error = null
            state.isLoading = false
            state.disabled = false
            state.accountsList = []
            state.cardInfo = {
                phone: "",
                email: "",
                cardHolder: "",
                cardNumber: "",
                san: "",
                cvv: "",
                expireDate: "",
                balance: 0,
                shippingAddress: {
                    DelvAddress1: "",
                    DelvCity: "",
                    DelvCountyState: "",
                    DelvCountryCode: "",
                    DelvPostCode: "",
                    Delivery: "",
                },
                shippingTrackingNo: "",
                type: "",
                typeText: "",
                status: "",
                statusText: "",
                description: "",
            }
            state.tokenIsValid = true
            state.currencyExchangeCheck = {
                    amount: '',
                    toAmount: '',
                    to: '',
                    from: ''
            }
            state.currencyExchangeResultCheck = null
            state.userLimits = {
                dailyLimit: null,
                monthlyLimit: null,
                currentDailyAmount: null,
                currentMonthlyAmount: null
            }
            state.accountTypesList = []
            state.chosenAccountTypeId = null
            state.sessionId = null
            state.loginHistory = []
            localStorage.clear()
        },
        setAccountsList(state, action) {
            state.accountsList = action.payload
        },
        setRegistrationWasSuccess(state, action) {
            state.registrationWasSuccess = action.payload
        },
        setUserEmail(state, action) {
            state.user.userData.email = action.payload
        },
        setKycList(state, action) {
            state.user.kycList = action.payload
        },
        setCardInfo(state, action) {
            state.cardInfo = action.payload
        },
        setIs2Fa(state, action) {
            state.user.verificationsList.twoFaVerifyEnabled = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setDisabled(state, {payload}) {
            state.disabled = payload
        },
        setCurrencyExchangeAmount(state, action) {
            state.currencyExchange.amount = action.payload
        },
        setCurrencyExchangeAmountCheck(state, action) {
            state.currencyExchangeCheck.amount = action.payload
        },
        setCurrencyExchangeToAmount(state, action) {
            state.currencyExchange.toAmount = action.payload
        },
        setCurrencyExchangeToAmountCheck(state, action) {
            state.currencyExchangeCheck.toAmount = action.payload
        },
        setCurrencyExchangeResult(state, action) {
            state.currencyExchangeResult = action.payload
        },
        setCurrencyExchangeResultCheck(state, action) {
            state.currencyExchangeResultCheck = action.payload
        },
        setPhoneList (state, action) {
            state.user.phoneList = action.payload
        },
        setIsSmsCode (state, action) {
            state.user.verificationsList.phoneVerifyEnabled = action.payload
        },
        setIs2faCode (state, action) {
            state.user.verificationsList.twoFaVerifyEnabled = action.payload
        },
        setCurrencyExchangeTo (state, action) {
            state.currencyExchange.to = action.payload
        },
        setCurrencyExchangeToCheck (state, action) {
            state.currencyExchangeCheck.to = action.payload
        },
        setCurrencyExchangeFrom (state, action) {
            state.currencyExchange.from = action.payload
        },
        setCurrencyExchangeFromCheck (state, action) {
            state.currencyExchangeCheck.from = action.payload
        },
        setNewEmail (state, action) {
            state.user.userData.email = action.payload
        },
        setTokenStatus (state, action) {
            state.tokenIsValid = action.payload
        },
        setToken (state, action) {
            state.userToken = action.payload
        },
        setSessionId (state, action) {
            state.sessionId = action.payload
        },
        setAccountTypesList (state, action) {
            state.accountTypesList = action.payload
        },
        setChosenAccountTypeId (state, action) {
            state.chosenAccountTypeId = action.payload
        },
        setLoginHistory (state, action) {
            state.loginHistory = action.payload
        },
        setAccountsLimit (state, action) {
            state.userLimits = action.payload
        }
    }
})

export const {setAccountsList,
    setCardInfo,
    setIsLoading,
    setDisabled,
    setCurrencyExchangeAmount,
    setCurrencyExchangeAmountCheck,
    setCurrencyExchangeToAmount,
    setCurrencyExchangeToAmountCheck,
    setPhoneList,
    setCurrencyExchangeResult,
    setCurrencyExchangeResultCheck,
    setCurrencyExchangeTo,
    setCurrencyExchangeFrom,
    setNewEmail,
    setTokenStatus,
    setCurrencyExchangeFromCheck,
    setCurrencyExchangeToCheck,
    setToken,
    setAccountTypesList,
    setAccountsLimit
} = userSlice.actions;

export default userSlice.reducer
