import React, {useEffect, useState} from 'react';
import s from './Templates.module.css'
import {Formik} from 'formik';
import plus from '../../../assets/img/personalAccount/whitePlus.svg'
import Table, {ColumnsType} from "antd/es/table";
import Icon from '@ant-design/icons';
import {ReactComponent as Trash} from '../../../assets/img/personalAccount/trash.svg';
import {ConfirmModal} from "./ConfirmModal/ConfirmModal";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {ITemplate} from "../../../types/interfaces";
import MyInput from "../../../components/ui/MyInput/MyInput";
import MyBtn from '../../../components/ui/MyBtn/MyBtn';
import TransparentButton from "../../../components/ui/TransparentButton/TransparentButton";
import CustomDropdownForAccounts from "../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../components/ui/CustomDropdown/CustomDropdown";
import {getTemplateListThunk} from "../../../store/reducers/ActionCreators";
import {useSelector} from "react-redux";
import {selectTemplatesByFilter} from "../../../store/reducers/TransactionsSlice/selector";
import {setFiltersTemplates} from "../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useAccountsForSelect} from "../../../hooks/useAccountsForSelect";
import {motion} from "framer-motion";
import CustomModal from "../../../components/ui/CustomModal/CustomModal";
import styles from "../../../styles/styles.module.css"

interface DataType {
    name: JSX.Element | string,
    recipient: string,
    type: string,
    action: JSX.Element | string,

}

const columns: ColumnsType<DataType> = [
    {
        title: 'Account number',
        dataIndex: 'accountNumber',
    },
    {
        title: 'name',
        dataIndex: 'name',
    },
    {
        title: 'recipient',
        dataIndex: 'recipient',
    },
    {
        title: 'type',
        dataIndex: 'type',
        className: `${s.typeColumn}`
    },
    {
        title: 'action',
        dataIndex: 'action',
        className: `${s.actionColumn}`
    },
];
const Templates = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const accountsList = useAccountsForSelect()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const selectedTemplatesList = useSelector(selectTemplatesByFilter)
    const initTemplatesList = useAppSelector(state => state.transactionsReducer.templates)

    const templatesNames: string[] = []

    const newTemplatesNames = () => {
        initTemplatesList?.map((template: ITemplate) => templatesNames.push(template.templateName))
    }
    newTemplatesNames()

    templatesNames.push("ALL")

    useEffect(() => {
        dispatch(getTemplateListThunk(token, accountId))
    }, [])

    const [showConfirmModal, setShowConfirmModal] = useState<boolean | null>(false)
    const [isTemplateSuccessfullyDeleted, setIsTemplateSuccessfullyDeleted] = useState<boolean | null>(null)
    const [TemplateForDelete, setTemplateForDelete] = useState({
        name: '',
        id: null! as number
    })

    const deletePaymentTemplate = (id: number, name: string) => {
        setTemplateForDelete({
            ...TemplateForDelete,
            id: id,
            name: name
        })
        setShowConfirmModal(true)
    }

    const tableData = selectedTemplatesList?.map((item: ITemplate) => {
        return {
            key: item.id,
            accountNumber: item.fromAccountMainIban,
            name: <div onClick={() => {
                handleNavigate(item.id, item.paymentType)
            }} className={s.linkDiv}>{item.templateName}</div>,
            recipient: `${item.toAccountUserName ? item.toAccountUserName : item.recipient}`,
            type: <span className={s.capitalize}>{item.paymentType ? item.paymentType : item.type}</span>,
            action: <div className={s.actionBlock} onClick={() => deletePaymentTemplate(item.id, item.templateName)}>
                <Icon className={s.deleteIcon} component={() => <Trash/>} rev={undefined}/> <p>Delete</p>
            </div>
        }
    })

    const handleNavigate = (param?: string | number, templateType?: string | number) => {
        navigate(`new-template/${param}/${templateType}`)
    }

    const getStatusOfDeletedTemplate = (res: any) => {
        if (res.data === "Deleted") {
            setIsTemplateSuccessfullyDeleted(true)
        } else {
            setIsTemplateSuccessfullyDeleted(false)
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <ConfirmModal setShowModal={setShowConfirmModal} showModal={showConfirmModal}
                          TemplateForDelete={TemplateForDelete}
                          getStatusOfDeletedTemplate={getStatusOfDeletedTemplate}
            />

            <CustomModal type="confirm" isNotify isModalOpen={isTemplateSuccessfullyDeleted} setIsModalOpen={setIsTemplateSuccessfullyDeleted}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.success}>Success!</span> Your template has been deleted successfully.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => {setIsTemplateSuccessfullyDeleted(null)}}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isTemplateSuccessfullyDeleted === false} setIsModalOpen={setIsTemplateSuccessfullyDeleted}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.error}>Failed!</span> Something went wrong. Please try again or contact us.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {setIsTemplateSuccessfullyDeleted(null)}}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.formTemplatesWrapper}>
                <h2 className={s.transfersWrapperTitle}>List of Templates</h2>

                <Formik
                    initialValues={{
                        accountNumber: '',
                        templateName: '',
                        recipient: '',
                    }}
                    onSubmit={(values) => {
                        dispatch(setFiltersTemplates(values))
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.accountInfoRow}>
                                <div className={s.dropdown_wrapper}>
                                    <p className={s.labelText}>Account Number</p>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        id={'accountNumber'}
                                        name={'accountNumber'}
                                    />
                                </div>

                                <div className={s.dropdown_wrapper}>
                                    <p className={s.labelText}>Template name</p>
                                    <CustomDropdown
                                        items={templatesNames}
                                        id={'templateName'}
                                        name={'templateName'}
                                    />
                                </div>
                            </div>

                            <div className={s.inputsWrapper}>
                                <div className={s.inputWrapper}>
                                    <p className={s.labelText}>Recipient</p>
                                    <MyInput
                                        name={'recipient'}
                                        id={'recipient'}
                                        value={values.recipient}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className={s.buttonWrapper}>
                                    <TransparentButton
                                        large
                                        isPersonalAccountBtn
                                        type={'submit'}
                                        title={'Show'}/>
                                </div>
                            </div>
                        </form>)}
                </Formik>
            </div>
            <div className={s.transfersWrapper}>
                <Table
                    bordered={false}
                    className={s.transfersTable}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                />

                {
                    selectedTemplatesList.map((template: ITemplate) =>
                        <div key={template.id} className={s.templateMobile}>
                            <div>
                                <p className={s.templateLabel}>Account number</p>
                                <p>
                                    <span className={s.templateValue}>
                                        {template.fromAccountMainIban}
                                    </span>
                                </p>
                            </div>

                            <div>
                                <p className={s.templateLabel}>Recipient</p>
                                <p>
                                    <span className={s.templateValue}>
                                        {template.toAccount ? template.toAccount : template.recipient}
                                    </span>
                                </p>
                            </div>

                            <div className={s.flex}>
                                <div>
                                    <p className={s.templateLabel}>Name</p>
                                    <p>
                                        <span onClick={() => {handleNavigate(template.id, template.type)}} className={s.linkDiv}>
                                            {template.templateName}
                                        </span>
                                    </p>
                                </div>

                                <div>
                                    <p className={s.templateLabel}>Type</p>
                                    <p>
                                        <span className={s.templateValue}>
                                            {template.type}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div>
                                <p className={s.templateLabel}>Action</p>
                                <div className={s.actionBlock} onClick={() => deletePaymentTemplate(template.id, template.templateName)}>
                                    <Icon className={s.deleteIcon} component={() => <Trash/>} rev={undefined}/> <p>Delete</p>
                                </div>
                            </div>

                        </div>)
                }

                <div className={s.tableButtonWrapper}>
                    <MyBtn medium icon={plus} style={{width: 180}}
                           isPersonalAccountBtn
                           title={'New template'}
                           onClick={() => {
                               handleNavigate('create', 'new')
                           }}/>
                </div>
            </div>
        </motion.div>
    );
};

export default Templates;


