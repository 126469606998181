import React, {Dispatch, SetStateAction} from 'react';
import s from "./KycPersonalStepOne.module.css";
import personalKyc from "../../../../../../assets/img/personalAccount/personal_kyc.png";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import {
    startVerificationProcessThunk
} from "../../../../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {motion} from 'framer-motion';
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import info from "../../../../../../assets/img/personalAccount/information.svg";
import {Popover} from "antd";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

type KycStatusType = string | null;

const KycPersonalStepOne = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const kycFormStatus: KycStatusType = useAppSelector(state => state.userReducer.user.flags.kycFormStatus)
    const kycVerificationStatus: any = useAppSelector(state => state.userReducer.user.flags.kycVerificationStatus)
    const kycRejectedReason = useAppSelector(state => state.userReducer.user.kycRejectedReason)
    const firstName = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName = useAppSelector(state => state.userReducer.user.userData.companyName)
    const accountType = useAppSelector(state => state.userReducer.user.userData.accountType)

    const startVerificationProcess = () => {

        dispatch(startVerificationProcessThunk(token, accountId))
            .then((res) => {
                window.open(res.data.idvUrl, '_blank');
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    }

    // const kycFormStatus: KycStatusType = "Rejected"
    // const kycVerificationStatus: any = "Approved"
    const isButtonShow: boolean | undefined = false
    const isReasonShow: boolean | undefined = kycRejectedReason?.isShowReasonConfidentiality
    const reason: string | undefined = kycRejectedReason?.statusReason

    const isShowVerificationButtonBlockPersonal = () => {
        if (!kycVerificationStatus && !kycFormStatus) return true

        if (accountType === "personal" && kycVerificationStatus !== 'Approved') {
            if (kycFormStatus && (kycFormStatus === 'Created' || kycFormStatus === 'Processed' || kycFormStatus === null)) {
                if (kycVerificationStatus !== 'Rejected' && isButtonShow) {
                    console.log("here")
                    return true
                } else return false
            } else return false
        } else {
            return false
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            { isShowVerificationButtonBlockPersonal()
                ? <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt=""/>
                        <div className={s.title}>KYC for <span className={s.capitalize}>{accountType}</span> Account</div>
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn title={kycFormStatus ? "Try again" : "Verification"} isPersonalAccountBtn onClick={() => startVerificationProcess()}/>
                    </div>
                </div>
                : <></>
            }

            { accountType === "business" && (kycFormStatus === 'Created' || kycFormStatus === null)
                ? <div className={s.verification_block}>
                    <div className={s.verification_flex}>
                        <img src={personalKyc} alt=""/>
                        <div className={s.title}>KYC for <span className={s.capitalize}>{accountType}</span> Account</div>
                    </div>
                    <div className={s.button_wrapper}>
                        <MyBtn title={kycFormStatus ? "Try again" : "Verification"} isPersonalAccountBtn onClick={() => startVerificationProcess()}/>
                    </div>
                </div>
                : <></>
            }

            { (kycFormStatus && kycVerificationStatus) &&
                <div className={s.questionnaire_list_block}>
                    <div className={s.questionnaire_title}>
                        Questionnaire List
                    </div>
                    <div className={s.questionnaire_table}>
                        <div className={s.table_header}>
                            <div>
                                <div className={s.header_value}>
                                    Owner
                                </div>

                                <div className={s.name}>
                                    {firstName} {lastName} {companyName}
                                </div>
                            </div>


                            <div>
                                <div className={s.header_value}>
                                    Questionnaire Status
                                    {kycFormStatus === 'Incorrect'
                                    && <Popover content={"Our support will contact you."}
                                         color={'#EEEFEF'}
                                         overlayInnerStyle={{
                                             fontFamily: 'Manrope',
                                             width: '280px',
                                             padding: '13px 16px',
                                             cursor: "pointer"
                                         }}
                                    >
                                        <img className={s.info} src={info} alt=""/>
                                    </Popover>}
                                </div>
                                <div className={s.row_value}>
                                    {kycFormStatus === 'Rejected' && <div className={s.rejected}>Rejected</div>}
                                    {kycFormStatus === 'Approved' && <div className={s.approved}>Approved</div>}
                                    {kycFormStatus === 'Incorrect' && <div className={s.incorrect}>Incorrect</div>}
                                    {kycFormStatus === 'Processed' && <div className={s.processed}>Processed</div>}
                                    {kycFormStatus === 'Created' && <div className={s.approved}>Created</div>}
                                </div>
                            </div>

                            <div>
                                <div className={s.header_value}>
                                    Verification Status
                                </div>

                                <div className={s.row_value}>
                                    {kycVerificationStatus === 'Rejected' && <div className={s.rejected}>Rejected</div>}
                                    {kycVerificationStatus === 'Approved' && <div className={s.approved}>Approved</div>}
                                    {kycVerificationStatus === 'Created' && <div className={s.approved}>Created</div>}
                                    {kycVerificationStatus === 'Processed' && <div className={s.processed}>Processed</div>}
                                </div>

                                <div className={s.reject_reason_block}>
                                    {
                                        (isReasonShow && kycVerificationStatus !== "Approved") && <div className={s.header_value}>Reason</div>
                                    }
                                    {
                                        (isReasonShow && kycVerificationStatus !== "Approved") && <div className={s.reason}>{reason}</div>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            }

            {
                (kycFormStatus === "Rejected"
                || kycVerificationStatus === "Rejected")
                && <div className={s.contact_block}>
                    Your {kycFormStatus === "Rejected" ? "KYC" : "verification"} was rejected. Feel free to contact <a className={s.mail} href="mailto:support@luxpay.lt">support@luxpay.lt</a> if you have any questions.
                 </div>
            }

        </motion.div>
    );
};

export default KycPersonalStepOne;
