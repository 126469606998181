import {ITransacton} from "../types/interfaces";

export const PrepareDataForPaymentTemplate = (values: ITransacton) => {

    const getPaymentType = () => {
        if (values.paymentRegions === "international") {
            return 'International Transaction(SWIFT)'
        } else if (values.paymentRegions === "domestic" && values.transferCurrency === "EUR") {
            return 'Europe Transaction (SEPA)'
        } else if (values.paymentRegions === "domestic" && values.transferCurrency === "GBP") {
            return 'FPS Transaction'
        }
    }

    console.log(values)

    return {
        accountId: values.accountId,
        templateName: values.templateName,
        type: getPaymentType(),
        paymentType: values.paymentRegions?.toLowerCase(),
        fromAccount: values.fromAccount || values.hiddenAccountNumberFrom,
        toAccount: values.toAccount,
        recipient: values.recipientsSwiftBic || values.toAccount || values.recipientsAccountNumber,
        recipientDetails: {
            beneficiaryName: values.fullName,
            beneficiaryAddress: values.recipientsAddress,
            iban: values.recipientsIban,
            swiftCode: values.recipientsSwiftBic,
            bankName: values.recipientsBank,
            bankAddress: values.recipientsBank,
            sortCode: values.sortCode,
            beneficiaryAccountNumber: values.recipientsAccountNumber,
            reference: values.reference,
        }
    }
}

export const PrepareDataForPaymentIntraTemplate = (values: ITransacton, accountId: number | null) => {
    const modifyTransferMethod = () => {
        if (values.transferMethod === 'phone number') {
            return 'phone'
        } else if (values.transferMethod === 'email') {
            return 'email'
        } else return 'account'
    }

    return {
        accountId: accountId,
        templateName: values.templateName,
        type: "Intra Transaction",
        fromAccount: values.accountNumber,
        toAccount: values.accNumberTo,
        transferType: modifyTransferMethod(),
        recipientDetails: {
            purpose: values.purpose,
            email: values.email,
            phone: values.phoneNumber
        }
    }
}
