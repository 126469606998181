import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import s from "./AccountConfirmationLetter.module.css";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {Formik} from "formik";
import {useAccountsForSelect} from "../../../../../hooks/useAccountsForSelect";
import {useSelector} from "react-redux";
import {
    selectAccountForConfirmationLetter,
    selectTopUpByAccount
} from "../../../../../store/reducers/TransactionsSlice/selector";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {setFilters, setFiltersTemplates} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import styles from "../../../../../styles/styles.module.css";
import iconInfo from "../../../../../assets/img/personalAccount/info-circle.svg";
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {getAccountConfirmationLetterThunk, setTokenState} from "../../../../../store/reducers/ActionCreators";

interface Helper {
    transferValues: {
        accountNumber: string;
    };
}

const DetailsForBankTransferHelper: React.FC<Helper> = ({ transferValues }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (transferValues.accountNumber) {
            dispatch(setFilters({ accountNumber: transferValues.accountNumber }));
        }
    }, [dispatch, transferValues.accountNumber]);

    return null;
}

const AccountConfirmationLetter = () => {

    const accountsList = useAccountsForSelect()
    const accountTopUpList = useSelector(selectTopUpByAccount)
    const dispatch = useAppDispatch()
    const selectedAccountForConfirmationLetter = useSelector(selectAccountForConfirmationLetter)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [isPDFDownloadSuccessfully, setIsPDFDownloadSuccessfully] = useState<boolean | null>(null);
    const token = useAppSelector(state => state.userReducer.userToken)

    const clearFilters = () => {
        dispatch(setFiltersTemplates(
            {
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: '',
                paymentRegion: ''
            }
        ))
    }

    useEffect(() => {
        return clearFilters()
    }, [accountTopUpList])

    const openPdfPopup = () => {
        if (!selectedAccountForConfirmationLetter) {
            return
        }

        setIsConfirmationModalOpen(true)
    }

    const getPdf = async () => {

        try {
            const response = await dispatch(getAccountConfirmationLetterThunk(token, accountId, selectedAccountForConfirmationLetter.number))

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'account_confirmation_letter.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            setIsPDFDownloadSuccessfully(true)
        } catch (e: any) {

            const contentType = e.response.headers['content-type'];
            if (contentType && contentType.includes('application/json')) {
                const errorData = await e.response.data; // Это будет Blob
                const reader = new FileReader();

                reader.onload = () => {
                    try {
                        if (typeof reader.result === "string") {
                            const jsonError = JSON.parse(reader.result);
                            const message = jsonError.detail || jsonError.title || jsonError;
                                if (message === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }

                                if (message === "Not enough money in accounts for commission") {
                                    setIsPDFDownloadSuccessfully(false)
                                    return
                                }

                        }

                    } catch (e) {
                        console.log('Не удалось распарсить сообщение об ошибке.')
                    }
                };

                reader.readAsText(errorData);
            } else {
                console.log("not json")
            }
        } finally {
            setIsConfirmationModalOpen(false)
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal type="confirm" isNotify isModalOpen={isPDFDownloadSuccessfully} setIsModalOpen={setIsPDFDownloadSuccessfully}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.success}>Success!</span> The write-off was successful.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn title={"Ok"} onClick={() => {setIsPDFDownloadSuccessfully(null)}}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isNotify isModalOpen={isPDFDownloadSuccessfully === false} setIsModalOpen={setIsPDFDownloadSuccessfully}>
                <div className={styles.modal_content}>
                    <div className={styles.popup_subtitle}>
                        <span className={styles.error}>Failed!</span> Write-off cannot be made due to insufficient funds.
                    </div>
                    <div className={styles.popup_button_block}>
                        <MyBtn isPersonalAccountBtn buttonType={"error"} title={"Ok"} onClick={() => {setIsPDFDownloadSuccessfully(null)}}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isConfirmationModalOpen} type={"info"}>
                <div className={styles.modal_content}>
                    <img src={iconInfo} alt="" className={styles.popup_icon}/>
                    <div className={styles.popup_title}>
                        <div className={s.popup_text_block}>
                            This is a paid service
                        </div>
                    </div>
                    <div className={styles.popup_subtitle}>
                        <div className={s.popup_text_block}>
                            50 EUR will be deducted from your account.
                        </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.popup_button_block}>
                            <TransparentButton
                                isPersonalAccountBtn
                                title={"Cancel"}
                                onClick={() => setIsConfirmationModalOpen(false)}
                                medium
                                buttonType={"info"}
                            />
                        </div>
                        <div className={styles.popup_button_block}>
                            <MyBtn
                                isPersonalAccountBtn
                                title={"Confirm"}
                                type={"submit"}
                                buttonType={"info"}
                                onClick={() => getPdf()}
                            />
                        </div>
                    </div>
                </div>
            </CustomModal>

            <div className={s.statementWrapper}>
                <div className={s.buttonsWrapper}>
                    <p className={s.downloadTitle}>Download as</p>
                    <p className={s.buttonTitle} onClick={() => openPdfPopup()}>PDF</p>
                </div>
            </div>
            <div>
                <Formik
                    initialValues={{
                        accountNumber: '',
                    }}
                    onSubmit={(values) => {}}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Account Number</p>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    id={'accountNumber'}
                                    name={'accountNumber'}
                                />
                            </div>
                            {(accountTopUpList?.all.length > 0 && accountTopUpList?.all)
                                ? accountTopUpList.all.map((e: any, key: any) => (
                                    <div className={s.data_wrapper} key={key}>
                                        <div className={s.row}>
                                            <span>Recipient Name</span>
                                            <span><b>{e.beneficiaryName}</b></span>
                                        </div>
                                        <div className={s.row}>
                                            <span>Recipient Account</span>
                                            <span><b>{e.iban}</b></span>
                                        </div>
                                        <div className={s.row}>
                                            <span>BIC Code</span>
                                            <span><b>{e.swift}</b></span>
                                        </div>
                                        <div className={s.row}>
                                            <span>Bank Name</span>
                                            <span><b>{e.bankName}</b></span>
                                        </div>
                                        <div className={s.row}>
                                            <span>Bank Address</span>
                                            <span><b>{e.bankAddress}</b></span>
                                        </div>
                                    </div>
                                ))
                                : <div className={s.no_data_found}>No data found</div>
                            }
                            <DetailsForBankTransferHelper transferValues={values} />
                        </form>)}
                </Formik>
            </div>
        </motion.div>
    );
};

export default AccountConfirmationLetter;
