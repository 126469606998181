import React, {useEffect, useLayoutEffect, useState} from 'react';
import s from './App.module.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/PublicPages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CAREERS,
    COMPLAINTS,
    CONTACT_US,
    CONTACT_US_FOR_AGENTS,
    COOKIE_POLICY,
    FAQ,
    AGENT_PROGRAM,
    PERSONAL_ACCOUNT,
    PRICING,
    PRICING_BUSINESS_ACCOUNT,
    PRICING_PERSONAL_ACCOUNT,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_BUSINESS,
    TERMS_AND_CONDITIONS_PERSONAL, KYC_SUCCESS, KYC_DECLINED, KYC_FAILURE, FRAUD_AND_SCAM_ALERT, KYC_SUCCESS_BUSINESS
} from "./Constants/RoutesConstants";
import Contacts from "./pages/PublicPages/Contacts/Contacts";
import Faq from "./pages/PublicPages/Faq/Faq";
import About from "./pages/PublicPages/About/About";
import Careers from "./pages/PublicPages/Careers/Careers";
import FeesPersonalAccount from "./pages/PublicPages/FeesPersonalAccount/FeesPersonalAccount";
import Pricing from "./pages/PublicPages/Pricing/Pricing";
import FeesBusinessAccount from "./pages/PublicPages/FeesBusinessAccount/FeesBusinessAccount";
import TermsAndConditions from "./pages/PublicPages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PublicPages/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./pages/PublicPages/CookiePolicy/CookiePolicy";
import Complaints from "./pages/PublicPages/Complaints/Complaints";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import ContactsForAgents from "./pages/PublicPages/ContactsForAgents/ContactsForAgents";
import { PricingBusinessAccountMobile } from './pages/PublicPages/Pricing/PricingBusinessAccountMobile';
import { PricingPersonalAccountMobile } from './pages/PublicPages/Pricing/PricingPersonalAccountMobile';
import TermsAndConditionsPrivate
    from "./pages/PublicPages/TermsAndConditions/TermsAndConditionsPrivate/TermsAndConditionsPrivate";
import TermsAndConditionsBusiness
    from "./pages/PublicPages/TermsAndConditions/TermsAndConditionsBusiness/TermsAndConditionsBusiness";
import AgentProgram from './pages/PublicPages/AgentProgram/AgentProgram';
import PersonalAccount from "./pages/PersonalAccount/PersonalAccount";
import Currency from "./pages/PersonalAccount/Currency/Currency";
import CreateTemplate from "./pages/PersonalAccount/Templates/CreateTemplate/CreateTemplate";
import Transfers from "./pages/PersonalAccount/Transfers/Transfers";
import Templates from "./pages/PersonalAccount/Templates/Templates";
import Security from "./pages/PersonalAccount/Security/Security";
import Accounts from "./pages/PersonalAccount/Accounts/Accounts";
import SettingsPersonal from "./pages/PersonalAccount/SettingsPersonal/Settings/Settings";
import {useAppSelector} from "./hooks/redux";
import RegistrationSteps from "./pages/RegistrationSteps/RegistrationSteps";
import LoginizationSteps from "./pages/LoginizationSteps/LoginizationSteps";
import PersonalAccountHeader from "./components/PersonalAccountHeader/PersonalAccountHeader";
import KycSuccess from "./pages/KYC/KycSuccess/KycSuccess";
import KycFailure from "./pages/KYC/KycFailure/KycFailure";
import KycDeclined from "./pages/KYC/KycDeclined/KycDeclined";
import ScamAlertLine from "./components/ScamAlertLine/ScamAlertLine";
import FraudAndScamAlert from "./pages/PublicPages/FraudAndScamAlert/FraudAndScamAlert";
import KycSuccessBusiness from "./pages/KYC/KycSuccessBusiness/KycSuccessBusiness";
import CookiePopup from "./components/CookiePopup/CookiePopup";

function App() {

    const {pathname} = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const user = useAppSelector(state => state.userReducer.userToken)

    const ProtectedRoute = (props: any) => {
        if (!props.user) {
            return <Navigate to={props.redirectPath} replace/>;
        }
        return props.children;
    };

    useLayoutEffect(() => {
        if (pathname.includes("personal_account")) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }, [pathname])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={s.app}>

            <CookiePopup/>

            {!isLoggedIn && <ScamAlertLine/>}

            <div className={s.desktop_header}>
                <Header/>
            </div>

            {
                isLoggedIn ? <div className={s.mobile_header_login}>
                    <PersonalAccountHeader/>
                </div> : <div className={s.mobile_header}>
                    <MobileHeader/>
                </div>
            }

            <Routes>
                <Route path={'/'} element={<Home/>}/>

                <Route path={'/personal_account'} element={
                    <ProtectedRoute redirectPath={'/login'} user={user}>
                        <PersonalAccount/>
                    </ProtectedRoute>
                }>
                    <Route path={"accounts"} element={<Accounts/>}/>
                    <Route path={'templates'}>
                        <Route index element={<Templates/>}/>
                        <Route path={"new-template/:id/:paymentType"} element={<CreateTemplate/>}/>
                    </Route>
                    <Route path={'transfers'} element={<Transfers/>}/>
                    <Route path={'currency'} element={<Currency/>}/>
                    <Route path={'security'} element={<Security/>}/>

                    <Route path={'personal'}>
                        <Route path={'settings'} element={<SettingsPersonal/>}/>
                    </Route>
                </Route>

                <Route path={ABOUT} element={<About/>}/>
                <Route path={CAREERS} element={<Careers/>}/>
                <Route path={CONTACT_US} element={<Contacts/>}/>
                <Route path={CONTACT_US_FOR_AGENTS} element={<ContactsForAgents/>}/>
                <Route path={FAQ} element={<Faq/>}/>
                <Route path={AGENT_PROGRAM} element={<AgentProgram/>}/>
                <Route path={PERSONAL_ACCOUNT} element={<FeesPersonalAccount/>}/>
                <Route path={BUSINESS_ACCOUNT} element={<FeesBusinessAccount/>}/>
                <Route path={PRICING} element={<Pricing/>}/>
                <Route path={PRICING_PERSONAL_ACCOUNT} element={<PricingPersonalAccountMobile/>}/>
                <Route path={PRICING_BUSINESS_ACCOUNT} element={<PricingBusinessAccountMobile/>}/>
                <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions/>}/>
                <Route path={TERMS_AND_CONDITIONS_PERSONAL} element={<TermsAndConditionsPrivate/>}/>
                <Route path={TERMS_AND_CONDITIONS_BUSINESS} element={<TermsAndConditionsBusiness/>}/>
                <Route path={PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                <Route path={COOKIE_POLICY} element={<CookiePolicy/>}/>
                <Route path={COMPLAINTS} element={<Complaints/>}/>
                <Route path={KYC_SUCCESS} element={<KycSuccess/>}/>
                <Route path={KYC_SUCCESS_BUSINESS} element={<KycSuccessBusiness/>}/>
                <Route path={KYC_DECLINED} element={<KycDeclined/>}/>
                <Route path={KYC_FAILURE} element={<KycFailure/>}/>
                <Route path={FRAUD_AND_SCAM_ALERT} element={<FraudAndScamAlert/>}/>

                <Route path={'/login'} element={<LoginizationSteps/>}/>
                <Route path={'/sign_up/:type?'} element={<RegistrationSteps/>}/>
            </Routes>

            <Footer/>
        </div>
    );

}

export default App;
